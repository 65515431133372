import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthenticatedGuard } from '@shared/services/auth/authenticated-guard.service';
import { Error404Component } from './pages/error404/error404.component';
import { FinComponent } from './pages/fin/fin.component';
import { InscriptionsComponent } from './components/inscriptions/inscriptions.component';

const routes: Routes = [
	/*{
		path: '',
		component: Error404Component,
  },*/
  {
    path: '',
    component: InscriptionsComponent,
    //canActivate: [AuthenticatedGuard]
  },
	{
		path: 'famille/:token',
		component: HomeComponent,
		// canActivate: [AuthenticatedGuard]
	},
	{
		path: 'famille/',
		component: HomeComponent,
		canActivate: [AuthenticatedGuard]
	},

	{
		path: 'error',
		component: Error404Component,
		// canActivate: [AuthenticatedGuard]
	},
	{
		path: 'fin',
		component: FinComponent,
	},
	/*{
		path: 'others',
		loadChildren: './pages/others/others.module#OthersModule',
		// canActivate: [AuthenticatedGuard]
	},*/
	// Not lazy-loaded routes
	// { path: 'login', component: LoginComponent },

	{ path: '**', redirectTo: '' }
	//Example with auth needed
	// {
	// 	path: 'Detail',
	// 	component: HomeComponent,
	// 	canActivate: [AuthenticatedGuard]
	// },,
	//Example with auth needed with role guard
	// {
	// 	path: 'Detail',
	// 	component: HomeComponent,
	// canActivate: [RoleGuard],
	// data: {
	// 	expectedRole: ['admin']
	// }
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
