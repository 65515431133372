<div *ngIf="selectedEleve" class="panel-body">
  <form novalidate (ngSubmit)="saveEleve()" [formGroup]="formEleve" *ngIf="formEleve">
    <div class="form-group col-sm-12 col-md-6" *ngIf="selectedEleve.isCreationFamille!=1">
      <label>{{ selectedEleve.civilite_libelle }} {{ selectedEleve.nom }}
        {{ selectedEleve.prenom }} {{ selectedEleve.sexe ? '(sexe '+selectedEleve.sexe+')' : ''}} né(e) le
        {{ selectedEleve.dateNaissStr | date }}</label>
    </div>
    <!--<div class="form-group col-md-6" *ngIf="!selectedEleve.isCreation">
      <label>Né(e) le {{ selectedEleve.dateNaissStr | date }}</label>
    </div>-->

    <div class="form-group col-sm-12 col-md-6" *ngIf="selectedEleve.isCreationFamille==1">
      <label class="col-xs-5" for="nom">Nom</label>
      <div class="col-xs-7">
        <input type="text" class="form-control col-xs-7" id="nom" formControlName="nom"
          onkeyup='this.value=this.value.toUpperCase()'>
      </div>
      <div class="alert alert-danger" *ngIf="isError(formEleve, 'nom')">
        Nom obligatoire
      </div>
    </div>
    <div class="form-group col-sm-12 col-md-6" *ngIf="selectedEleve.isCreationFamille==1">
      <label class="col-xs-5" for="prenom">Prénom</label>
      <div class="col-xs-7">
        <input type="text" class="form-control col-xs-7" id="prenom" formControlName="prenom"
          onkeyup='this.value=this.value.charAt(0).toUpperCase()+this.value.substring(1)'>
      </div>
      <div class="alert alert-danger" *ngIf="isError(formEleve, 'prenom')">
        Prénom obligatoire
      </div>
    </div>


    <div class="form-group col-sm-12 col-md-6" *ngIf="selectedEleve.isCreationFamille==1">
      <label class="col-xs-5" for="dateNaissStr">Date naissance</label>
      <div class="col-xs-7">
        <input placeholder="Date naissance" class="form-control col-xs-7" id="dateNaissStr"
          formControlName="dateNaissStr" [owlDateTimeTrigger]="date1" [owlDateTime]="date1">
        <owl-date-time [pickerType]="'calendar'" #date1></owl-date-time>
      </div>
      <div class="alert alert-danger" *ngIf="isError(formEleve, 'dateNaissStr')">
        Date naissance obligatoire
      </div>
    </div>
    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="civilite">Civilité</label>
      <div class="col-xs-7">
        <select id="civilite" class="form-control" formControlName="civilite">
          <option *ngFor="let option of listeCivilite" [ngValue]="option.id">
            {{option.libelle}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="civilite">Sexe</label>
      <div class="col-xs-7">
        <select id="sexe" class="form-control" formControlName="sexe">
          <option *ngFor="let option of listeSexe" [ngValue]="option.id">
            {{option.libelle}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="telephonePortable">Téléphone mobile</label>
      <div class="col-xs-7">
        <input type="text" class="form-control col-xs-7" id="telephonePortable" formControlName="telephonePortable">
      </div>
      <div class="alert alert-danger" *ngIf="isError(formEleve, 'telephonePortable')">
        Téléphone mobile mal formaté (doit commencer par 06 ou 07)
      </div>
    </div>
    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="email">Courriel</label>
      <div class="col-xs-7">
        <input type="text" class="form-control col-xs-7" id="email" formControlName="email">
      </div>
      <div class="alert alert-danger" *ngIf="isError(formEleve, 'email')">
        Courriel mal formaté
      </div>
    </div>


    <!--liste déroulante statut-->
    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="statut">Statut</label>
      <div class="col-xs-7">
        <select id="statut" class="form-control" formControlName="statut">
          <option *ngFor="let option of listeStatutEleve" [ngValue]="option.id">
            {{option.libelle}}
          </option>
        </select>
      </div>
    </div>

    <!--liste déroulante niveau scolaire-->
    <div class="form-group col-sm-12 col-md-6">
      <label class="col-xs-5" for="niveauScolaire">Niveau scolaire</label>
      <div class="col-xs-7">
        <select id="niveauScolaire" class="form-control" formControlName="niveauScolaire">
          <option *ngFor="let option of listeNiveauScolaire" [ngValue]="option.id">
            {{option.libelle}}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group col-sm-12 col-md-6">
      <input type="checkbox" id="accordMedia" formControlName="accordMedia" />
      <label for="accordMedia">Accord multimédia (nécessaire pour participer aux spectacles)</label>
    </div>
    <div class="form-group col-sm-12 col-md-6">
      <input type="checkbox" id="bool_01" formControlName="bool_01" />
      <label for="bool_01">Pass 92</label>
    </div>

    <div *ngIf="isBoutonsVisibles">
      <button [disabled]="formEleve.invalid" type="submit" class="btn btn-primary">Valider</button>
      <button type="button" class="btn btn-default" (click)="canceled.emit()">Annuler</button>
    </div>
  </form>
</div>
