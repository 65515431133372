import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RoleGuardService } from './services/auth/role-guard.service';
import { AuthenticatedGuard } from './services/auth/authenticated-guard.service';
import { AuthService } from './services/auth/auth.service';
import { EnvServiceProvider } from './services/env/env-service.factory';
@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [RoleGuardService, AuthService, AuthenticatedGuard, EnvServiceProvider]
})
export class SharedModule {}
