
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { 
	Router,
	CanActivate,
	ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
/**
 * Role guard service
 * Manage the Authorization of the current user
 */
@Injectable()
export class RoleGuardService implements CanActivate {
	/**
	 * Constructeur
	 * @param auth AuthService
	 * @param router Router
	 */
	constructor(public auth: AuthService, public router: Router) {}
	/**
	 * Check if the user can access the target route
	 * @param route 
	 */
	canActivate(route: ActivatedRouteSnapshot): boolean {
		const jwt = new JwtHelperService();
		// this will be passed from the route config
		// on the data property
		const expectedRole = route.data.expectedRole;
		const token = localStorage.getItem('id_token');
		// decode the token to get its payload
		const tokenPayload = jwt.decodeToken(token);
		if (
			!this.auth.authenticated() || !expectedRole.includes(tokenPayload.role)
		) {
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
}
