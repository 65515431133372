import { Component } from '@angular/core';
import { Login } from './shared/models/login';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'cham-V1';
	loginInfo: Login = {
		first_name:'Morgan',
		last_name:'LC',
		avatar:'avatar.png',
		title:'Developer'
};

constructor() {
	localStorage.setItem('date_livraison', environment.date);
	localStorage.setItem('api', environment.API);
	localStorage.setItem('server', environment.server);
}
	
}
