<div class="breadcrumb" *ngIf="etape < 7">
  <a (click)="goEtape(1)" *ngIf="etape != 1">Adresse</a
  ><span *ngIf="etape == 1">Adresse</span>
  <span *ngIf="etape > 1"> / </span>
  <a (click)="goEtape(2)" *ngIf="etape > 2">Responsables</a
  ><span *ngIf="etape == 2">Responsables</span>
  <span *ngIf="etape > 2"> / </span>
  <a (click)="goEtape(3)" *ngIf="etape > 3">Elèves</a
  ><span *ngIf="etape == 3"
    >Elèves
    {{
      etape == 3
        ? " (" +
          (indice_eleve_selectionne + 1) +
          "/" +
          famille.eleves.length +
          ")"
        : ""
    }}</span
  >
  <span *ngIf="etape > 3"> / </span>
  <a (click)="goEtape(4)" *ngIf="etape > 4">Synthèse pré-inscriptions</a
  ><span *ngIf="etape == 4">Synthèse pré-inscriptions</span>
  <span *ngIf="etape > 4"> / </span>
  <a (click)="goEtape(5)" *ngIf="etape > 5">Estimation du coût / Commentaire</a
  ><span *ngIf="etape == 5">Estimation du coût / Commentaire</span>
  <span *ngIf="etape > 5"> / </span>
  <a (click)="goEtape(6)" *ngIf="etape > 6">Rendez-vous</a
  ><span *ngIf="etape == 6">Rendez-vous</span>
</div>
<div *ngIf="famille" class="panel-body">
  <div *ngIf="isDebug">
    Mode debug actif<br />etape {{ etape }} - indice_eleve_selectionne
    {{ indice_eleve_selectionne }} - nbEleves:
    {{ famille && famille.eleves ? famille.eleves.length : 0 }}
  </div>
  <!--<div class="row mb-lg">
    <div class="col-md-9">
      <div class="row">
        <div class="col-xs-4 text-right">Famille</div>
        <div class="col-xs-8"><b>{{ famille.nom }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4 text-right">N°</div>
        <div class="col-xs-8"><b>{{ famille.id }}</b>
        </div>
      </div>
    </div>
  </div>
-->

  <div class="row mb-lg" *ngIf="etape == 1 || etape == 4">
    <div class="col-md-9" *ngIf="famille.adresse">
      <h2>Adresse</h2>
      <b *ngIf="etape == 4">
        {{ famille.adresse.adresse1 ? famille.adresse.adresse1 : "" }}
        <br />{{ famille.adresse.adresse2 ? famille.adresse.adresse2 : "" }}
        <br />{{ famille.adresse.codePostal + " " + famille.adresse.ville }}
        <br />
      </b>

      <div *ngIf="etape == 1">
        <form
          novalidate
          (ngSubmit)="saveAdresse()"
          [formGroup]="formAdresse"
          *ngIf="formAdresse"
        >
          <div class="form-group">
            <label for="adresse1">Adresse</label>
            <input
              type="text"
              class="form-control"
              id="adresse1"
              formControlName="adresse1"
            />
            <!-- for et id doivent avoir la même valeur-->
            <!-- class="form-control" : uniquement pour bootstrap -->
            <div
              class="alert alert-danger"
              *ngIf="isError(formAdresse, 'adresse1')"
            >
              <!--form.get('nomDuFormControl')-->
              Ligne 1 adresse obligatoire
            </div>
          </div>
          <div class="form-group">
            <label for="adresse2">Complément</label>
            <input
              type="text"
              class="form-control"
              id="adresse2"
              formControlName="adresse2"
            />
            <div
              class="alert alert-danger"
              *ngIf="isError(formAdresse, 'adresse2')"
            >
              Complément adresse obligatoire
            </div>
          </div>
          <div class="form-group">
            <label for="codePostal">Code postal</label>
            <input
              type="text"
              class="form-control"
              id="codePostal"
              formControlName="codePostal"
            />
            <div
              class="alert alert-danger"
              *ngIf="isError(formAdresse, 'codePostal')"
            >
              Code postal obligatoire
            </div>
          </div>
          <div class="form-group">
            <label for="ville">Ville</label>
            <input
              type="text"
              class="form-control"
              id="ville"
              formControlName="ville"
            />
            <div
              class="alert alert-danger"
              *ngIf="isError(formAdresse, 'ville')"
            >
              Ville adresse obligatoire
            </div>
          </div>
          <div class="row">
            <button
              *ngIf="etape > 1"
              type="button"
              class="btn btn-primary"
              (click)="goEtapePrecedente()"
            >
              Etape précédente
            </button>
            <button
              [disabled]="formAdresse.invalid"
              type="submit"
              class="btn btn-success"
            >
              Valider l'adresse
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row mb-lg" *ngIf="etape == 2 || etape == 4">
    <h2>Responsables</h2>
    <div>
      Le responsable principal est l’interlocuteur privilégié du conservatoire
      pour l’ensemble de la famille. Il ne doit y avoir qu’un seul responsable
      principal par famille.
    </div>
    <ngx-datatable
      class="material"
      [rows]="famille.responsables"
      [columns]="responsables_columns"
      [columnMode]="'flex'"
      [headerHeight]="40"
      [rowHeight]="'auto'"
      [reorderable]="true"
    >
      <!--[columnMode]="'force'" -->
    </ngx-datatable>
    <div class="alert alert-danger" *ngIf="nbResponsablePrincipal == 0">
      Attention : vous devez spécifier un responsable principal en cochant la
      case responsable principal sur l'un des responsables
    </div>
    <div class="alert alert-danger" *ngIf="nbResponsablePrincipal > 1">
      Attention : vous devez spécifier un seul responsable principal
    </div>
    <div class="row" *ngIf="etape == 2">
      <button
        *ngIf="etape > 1"
        type="button"
        class="btn btn-primary"
        (click)="etape = etape - 1"
      >
        Etape précédente
      </button>
      <button
        type="button"
        class="btn btn-w-m btn-primary"
        (click)="addResponsable()"
      >
        Ajouter un responsable
      </button>
      <button
        type="button"
        (click)="validerResponsables()"
        class="btn btn-success"
      >
        Valider les responsables
      </button>
    </div>
  </div>

  <div class="row mb-lg" *ngIf="etape == 3 || etape == 4">
    <h2>Elèves</h2>
    <ngx-datatable
      class="material"
      [rows]="famille.eleves"
      [columns]="eleves_columns"
      [columnMode]="'force'"
      [headerHeight]="40"
      [footerHeight]="0"
      [rowHeight]="'40'"
      [reorderable]="true"
    >
    </ngx-datatable>
    <hr />
    <div *ngIf="indice_eleve_selectionne >= 0 && etape == 3">
      <div *ngIf="true">
        <!--selectedEleve.id-->
        <h2>Elève {{ selectedEleve.nom }} {{ selectedEleve.prenom }}</h2>
        <app-eleve
          #editEleveComponent
          [selectedEleve]="selectedEleve"
          [isBoutonsVisibles]="false"
          (eleveSaved)="eleveSaved($event)"
          (canceled)="editEleve.close()"
        ></app-eleve>

        <div *ngIf="selectedEleve.id != null">
          <!--on n'affiche pas les eleves non enregistres => imposer qu'ils soient enregistres avant-->
          <h3>
            Pré-inscriptions de {{ selectedEleve.nom }}
            {{ selectedEleve.prenom }}
            <button
              *ngIf="etape == 3"
              type="button"
              class="btn btn-w-m btn-primary"
              (click)="addInscription(selectedEleve.id)"
            >
              Ajouter une nouvelle inscription
            </button>
          </h3>
          <ngx-datatable
            class="material"
            [rows]="inscriptionsDeEleve(selectedEleve.id)"
            [columns]="inscriptions_columns"
            [columnMode]="'force'"
            [headerHeight]="40"
            [footerHeight]="0"
            [rowHeight]="'auto'"
            [reorderable]="true"
          >
          </ngx-datatable>
          <div *ngIf="estEleveCourantFISansFM" class="alert alert-danger">
            La formation musicale est indissociable de la formation
            instrumentale pour un enseignement musical de qualité. C’est
            pourquoi, toute ré-inscription à une formation instrumentale doit
            être accompagnée obligatoirement d’une ré-inscription à un cours de
            formation musicale
          </div>
        </div>
      </div>
    </div>
    <!--Afficher les tableaux des inscriptions de chaque eleve si etape 4 ou 6-->
    <div *ngIf="etape == 4">
      <div *ngFor="let eleve of famille.eleves">
        <h2>
          Demande de pré-inscription de {{ eleve.nom }} {{ eleve.prenom }}
        </h2>
        <ngx-datatable
          class="material"
          [rows]="inscriptionsDeEleve(eleve.id)"
          [columns]="inscriptions_columns"
          [columnMode]="'force'"
          [headerHeight]="40"
          [footerHeight]="0"
          [rowHeight]="'auto'"
          [reorderable]="true"
          [scrollbarV]="false"
          [scrollbarH]="true"
        >
        </ngx-datatable>
      </div>
    </div>
    <div
      class="alert alert-danger"
      *ngIf="etape == 4 && existeEleveCreeParFamille()"
    >
      <!--voir si on sait qu'un éléève a été ajouté => ne l'afficher que dans ce cas-->
      Pour un nouvel élève ({{ libelleElevesCreesParFamille() }}), nous vous
      rappelons que l'inscription sera fonction des places disponibles.
    </div>
    <div class="alert alert-danger" *ngIf="nbReinscriptions == 0 && etape == 4">
      Vous n'avez effectué aucune inscription pour la nouvelle année.
    </div>
    <div
      class="alert alert-danger"
      *ngIf="etape == 3 && existeEleveCreeParFamille()"
    >
      Pour un nouvel élève ({{ libelleElevesCreesParFamille() }}), nous vous
      rappelons que l'inscription sera fonction des places disponibles.
    </div>
    <div class="row">
      <button
        *ngIf="etape > 1"
        type="button"
        class="btn btn-primary"
        (click)="goEtapePrecedente()"
      >
        Etape précédente
      </button>
      <!--PL 04/2020 bouton addEleve *ngIf="false && etape==3"-->
      <button
        type="button"
        *ngIf="etape == 3"
        class="btn btn-w-m btn-primary"
        (click)="addEleve()"
      >
        Ajouter un élève
      </button>
      <!-- pas d'ajout d'élève pour le moment -->
      <button
        [disabled]="estEleveCourantFISansFM"
        type="button"
        *ngIf="etape == 3"
        (click)="validerEleve()"
        class="btn btn-success"
      >
        {{
          indice_eleve_selectionne + 1 == famille.eleves.length
            ? "Synthèse pré-inscriptions"
            : "Elève suivant"
        }}
      </button>
      <button
        type="button"
        *ngIf="etape == 4"
        (click)="validerEleves()"
        class="btn btn-success"
      >
        Valider les pré-inscriptions
      </button>
    </div>
  </div>

  <!--Affichage factures-->
  <div class="row mb-lg" *ngIf="etape == 5 && factures">
    <h3>Estimation du coût</h3>
    <div *ngFor="let facture of factures">
      <ngx-datatable
        class="material"
        [rows]="facture.lignesFacture"
        [columns]="facture_columns"
        [columnMode]="'force'"
        [headerHeight]="40"
        [footerHeight]="0"
        [rowHeight]="'auto'"
        [reorderable]="true"
      >
      </ngx-datatable>
      <h3>Montant total : {{ facture.montantTotal | currency : "EUR" }}</h3>
      <br />
      <label
        >Ce coût est une estimation qui sera confirmée lors du rendez-vous avec
        le secrétariat du conservatoire.<br />
      </label>
      <label
        >IMPORTANT : <br />
        La musique, c’est jouer ensemble. Le conservatoire encourage les élèves
        à participer aux ensembles (dits « cours collectifs »). C’est pourquoi,
        pour un élève inscrit en formation instrumentale et musicale, le cours
        collectif est offert dans la limite des places disponibles.
        <br />
        <br />
        (Si un cours apparait avec un tarif de 999,99€, ne vous inquiétez pas,
        cela signifie qu'il manque un tarif dans le logiciel. Le cours a pu être
        remplacé par un autre ou c’est une erreur. La correction sera réalisée
        lors du rendez-vous. Merci de votre compréhension.)
      </label>
      <label>
        Enfin, comme l'année dernière, nous nous permettrons de solliciter votre
        générosité. Lors de votre rendez-vous, vous aurez la possibilité de
        <b>faire un don</b> (par chèque) pour soutenir l'association. Ces dons,
        fiscalement déductibles, sont précieux pour le Conservatoire, pour
        renforcer sa dynamique et son ouverture artistique. Ils nous ont déjà
        permis d'acquérir une batterie électronique, des instruments pour
        enrichir l’atelier de percussions afro-cubaines et les classes d’éveil.
        Merci à nos généreux donateurs ! Nous aurons bien sûr à cœur de vous
        informer régulièrement sur les projets engagés...
      </label>
    </div>
  </div>

  <div class="row mb-lg" *ngIf="etape == 5 || etape == 6">
    <form
      novalidate
      (ngSubmit)="saveCommentaire()"
      [formGroup]="formCommentaire"
    >
      <div class="form-group">
        <h3 for="commentaire">Commentaire à destination du conservatoire</h3>
        <textarea
          class="form-control"
          id="commentaire"
          formControlName="commentaire"
        ></textarea>
      </div>
    </form>
  </div>

  <div class="row" *ngIf="etape == 5">
    <button
      *ngIf="etape > 1"
      type="button"
      class="btn btn-primary"
      (click)="goEtapePrecedente()"
    >
      Etape précédente
    </button>
    <button
      type="button"
      (click)="valideInscriptions()"
      class="btn btn-success"
    >
      Valider
    </button>
  </div>

  <div class="row" *ngIf="etape == 6">
    <!--<div *ngIf="formRendezVous && formRendezVous.value.typeRendezVous && !libelleRendezVous">
      Cette année, il n’y a plus qu’un seul type de rendez-vous.<br/>
      Pour les réinscriptions en Eveil ou en Groupe Vocal, merci de sélectionner les rendez-vous du mercredi matin.
    </div><br />-->
    <form
      novalidate
      (ngSubmit)="saveRendezVous()"
      [formGroup]="formRendezVous"
      *ngIf="formRendezVous"
    >
      <div
        class="form-group"
        *ngIf="
          formRendezVous &&
          formRendezVous.value.typeRendezVous &&
          !libelleRendezVous
        "
      >
        <label for="typeRendezVous">Type rendez-vous</label>
        <select
          id="typeRendezVous"
          class="form-control"
          formControlName="typeRendezVous"
          (change)="typeRendezVousChanged()"
        >
          <option *ngFor="let option of listeTypeRendezVous" [ngValue]="option">
            {{ option.libelle }}
          </option>
        </select>
      </div>
    </form>
    <full-calendar
      #calendar
      defaultView="dayGridMonth"
      [plugins]="calendarPlugins"
      themeSystem="bootstrap"
      [events]="listeRendezVousDisponibles"
      [locale]="options.locale"
      (eventClick)="eventClick($event)"
      [views]="options.views"
      [header]="options.header"
      [buttonText]="options.buttonText"
      [titleFormat]="options.titleFormat"
      *ngIf="
        formRendezVous &&
        formRendezVous.value.typeRendezVous &&
        !libelleRendezVous
      "
    >
    </full-calendar>
    <h3 *ngIf="libelleRendezVous">{{ libelleRendezVous }}</h3>
    <div *ngIf="libelleRendezVous">
      Votre pré-inscription n'est pas terminée
    </div>
    <br /><br />
    <div *ngIf="libelleRendezVous">
      <form novalidate [formGroup]="formRendezVous" *ngIf="formRendezVous">
        <div class="form-group">
          <input
            type="checkbox"
            id="consentement"
            formControlName="consentement"
          />
          <label
            for="consentement"
            [class]="
              formRendezVous.value.consentement != true
                ? 'alert alert-danger'
                : ''
            "
            >En soumettant ce formulaire, j'accepte que les informations saisies
            dans ce formulaire soient utilisées dans le cadre de votre
            inscription aux activités du conservatoire.</label
          >
        </div>
        <button
          *ngIf="etape > 1"
          type="button"
          class="btn btn-primary"
          (click)="goEtapePrecedente()"
        >
          Etape précédente
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="libelleRendezVous = ''"
          *ngIf="libelleRendezVous"
        >
          Changer le rendez-vous
        </button>
        <button
          [disabled]="formRendezVous.value.consentement != true"
          type="button"
          class="btn btn-w-m btn-success"
          (click)="saveRendezVous()"
          *ngIf="libelleRendezVous"
        >
          Envoyer la demande de pré-inscription
        </button>
      </form>
    </div>
  </div>

  <div class="row" *ngIf="etape == 7">
    <h2>{{ libelleRendezVous }} confirmé</h2>
    <h4>
      Votre inscription sera ferme et définitive une fois qu’elle aura été
      validée par l’administration du Conservatoire suite au rendez-vous<br /><br />
      <strong>IMPORTANT</strong> : Lors du rendez-vous, merci de vous munir de :
      <ul>
        <li>vos moyens de paiement (chèque, espèces, Pass92, Pass+),</li>
        <li>un justificatif de domicile</li>
        <li>carte d'étudiant éventuellement</li>
        <li>le récapitulatif que vous allez recevoir par mail</li>
        <li>
          une procuration, si ce n’est pas vous le responsable principal qui
          venez au rendez-vous
        </li>
      </ul>
    </h4>
    <h4>
      Vous allez recevoir dans quelques minutes un courriel de confirmation.<br />
    </h4>

    <h4>
      Merci de prendre connaissance du Règlement des Etudes disponible sur le
      site du Conservatoire.
      <a
        target="_blank"
        href="https://conservatoire-bois-colombes.fr/http-conservatoire-bois-colombes-fr-wp-content-uploads-2019-06-reglement-des-etudes-signe-1-pdf"
        >https://conservatoire-bois-colombes.fr/http-conservatoire-bois-colombes-fr-wp-content-uploads-2019-06-reglement-des-etudes-signe-1-pdf</a
      >
    </h4>

    <h4>Vous pouvez fermer votre navigateur</h4>
  </div>
</div>

<ng-template
  #listActionTemplateResponsable
  let-row="row"
  let-rowIndex="rowIndex"
  let-value="value"
  ngx-datatable-cell-template
>
  <button
    *ngIf="etape < 5 && row.isDeleted != 1"
    (dblclick)="onEditResponsable(rowIndex, row)"
    (click)="onEditResponsable(rowIndex, row)"
    title="Détails"
    class="btn btn-primary dim"
    type="button"
  >
    <i class="fa fa-edit"></i>
  </button>
  <button
    *ngIf="row.isCreationFamille == 1 && row.isDeleted != 1"
    (dblclick)="onDeleteResponsable(rowIndex, row)"
    (click)="onDeleteResponsable(rowIndex, row)"
    title="Supprimer responsable"
    class="btn btn-primary dim"
    type="button"
  >
    <!--*ngIf="row.idCreation"-->
    <i class="fa fa-trash"></i>
  </button>
  <label *ngIf="row.isDeleted == 1">Suppression demandée</label>
</ng-template>

<ng-template
  #listActionTemplateEleve
  let-row="row"
  let-rowIndex="rowIndex"
  let-value="value"
  ngx-datatable-cell-template
>
  <!--<button *ngIf="etape<5 && row.isDeleted!=1" (dblclick)="onEditEleve(rowIndex, row)"
    (click)="onEditEleve(rowIndex, row)" title="Détails" class="btn btn-primary dim" type="button">
    <i class="fa fa-edit"></i>
  </button>-->
  <!--row.isCreationFamille=='1'-->
  <button
    *ngIf="row.isCreationFamille == 1 && row.isDeleted != 1"
    (dblclick)="onDeleteEleve(rowIndex, row)"
    (click)="onDeleteEleve(rowIndex, row)"
    title="Supprimer élève"
    class="btn btn-primary dim"
    type="button"
  >
    <i class="fa fa-trash"></i>
  </button>
  <label *ngIf="row.isDeleted == 1">Suppression demandée</label>
  <label *ngIf="row.isValide == 1 && row.isDeleted != 1">&#x2713;</label
  ><!-- symbol coche -->
</ng-template>

<ng-template
  #listActionTemplateCours
  let-row="row"
  let-rowIndex="rowIndex"
  let-value="value"
  ngx-datatable-cell-template
>
  <div *ngIf="isDebug">{{ row | json }}</div>
  <button
    *ngIf="isDemandeReinscriptionPossible(row)"
    (dblclick)="onConfirmInscription(rowIndex, row)"
    (click)="onConfirmInscription(rowIndex, row)"
    title="Confirmer inscription"
    class="btn btn-primary dim"
    type="button"
  >
    Pré-inscrire
  </button>
  <button
    *ngIf="
      ((row.is_creation_famille == 1 ||
        row.is_creation_famille_an_suivante == 1) &&
        row.isDeleted != 1) ||
      row.isReinscriptionDemandee == true
    "
    (dblclick)="onDeleteInscription(rowIndex, row)"
    (click)="onDeleteInscription(rowIndex, row)"
    title="Supprimer inscription"
    class="btn btn-primary dim"
    type="button"
  >
    <i class="fa fa-trash"></i>
  </button>
  <label *ngIf="row.isDeleted == 1">Suppression demandée</label>
  <span *ngIf="row.isReinscriptionDemandee">Pré-inscription demandée</span>
  <div *ngIf="row.isReinscriptionImpossible || row.ok == '-2'">
    Pré-inscription impossible
  </div>
  <div *ngIf="row.id_inscription_an_suiv != null">
    Pré-inscription prise en compte
  </div>
  <div *ngIf="row.libelleCours">{{ row.libelleCours }}</div>
</ng-template>

<ngx-smart-modal #editResponsable identifier="editResponsable">
  <h2>Responsable famille {{ famille.nom }}</h2>
  <form
    novalidate
    (ngSubmit)="saveResponsable()"
    [formGroup]="formResponsable"
    *ngIf="formResponsable"
  >
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="nom">Nom</label>
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control"
          id="nom"
          formControlName="nom"
          onkeyup="this.value=this.value.toUpperCase()"
          *ngIf="selectedResponsable.isCreationFamille == 1"
        />
        <div *ngIf="selectedResponsable.isCreationFamille != 1">
          {{ selectedResponsable.nom }}
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="isError(formResponsable, 'nom')">
        Nom obligatoire
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="prenom">Prénom</label>
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control"
          id="prenom"
          formControlName="prenom"
          onkeyup="this.value=this.value.charAt(0).toUpperCase()+this.value.substring(1)"
          *ngIf="selectedResponsable.isCreationFamille == 1"
        />
        <div *ngIf="selectedResponsable.isCreationFamille != 1">
          {{ selectedResponsable.prenom }}
        </div>
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formResponsable, 'prenom')"
      >
        Prénom obligatoire
      </div>
    </div>
    <div class="form-group col-xs-12">
      <input type="checkbox" id="principal" formControlName="principal" />
      <label for="principal">Responsable principal</label>
      <div>
        Le responsable principal est l’interlocuteur privilégié du conservatoire
        pour l’ensemble de la famille. Il ne doit y avoir qu’un seul responsable
        principal par famille.
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="lienParente">Lien parenté</label>
      <div class="col-xs-7" *ngIf="selectedResponsable.isCreationFamille != 1">
        {{ selectedResponsable.lien_parente_libelle }}
      </div>
      <div class="col-xs-7" *ngIf="selectedResponsable.isCreationFamille == 1">
        <select
          id="lienParente"
          class="form-control"
          formControlName="lienParente"
        >
          <option *ngFor="let option of listeParente" [ngValue]="option.id">
            {{ option.libelle }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="civilite">Civilité</label>
      <div class="col-xs-7">
        <select
          id="civilite"
          class="form-control"
          formControlName="civilite"
          *ngIf="selectedResponsable.isCreationFamille == 1"
        >
          <option *ngFor="let option of listeCivilite" [ngValue]="option.id">
            {{ option.libelle }}
          </option>
        </select>
        <div *ngIf="selectedResponsable.isCreationFamille != 1">
          {{ selectedResponsable.civilite_libelle }}
        </div>
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="telephoneDomicile">Téléphone domicile</label>
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control"
          id="telephoneDomicile"
          formControlName="telephoneDomicile"
        />
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formResponsable, 'telephoneDomicile')"
      >
        Téléphone domicile mal formaté
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="telephoneMobile">Téléphone mobile</label>
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control col-xs-8"
          id="telephoneMobile"
          formControlName="telephoneMobile"
        />
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formResponsable, 'telephoneMobile')"
      >
        Téléphone mobile mal formaté (doit commencer par 06 ou 07)
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="telephoneProfessionnel"
        >Téléphone professionnel</label
      >
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control"
          id="telephoneProfessionnel"
          formControlName="telephoneProfessionnel"
        />
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formResponsable, 'telephoneProfessionnel')"
      >
        Téléphone professionnel mal formaté
      </div>
    </div>
    <div class="form-group col-xs-12">
      <label class="col-xs-5" for="email">Courriel</label>
      <div class="col-xs-7">
        <input
          type="text"
          class="form-control"
          id="email"
          formControlName="email"
        />
      </div>
      <div class="alert alert-danger" *ngIf="isError(formResponsable, 'email')">
        Courriel mal formaté
      </div>
    </div>
    <div class="form-group col-xs-12" *ngIf="messageErreurResponsable">
      <div *ngIf="messageErreurResponsable" class="alert alert-danger">
        {{ messageErreurResponsable }}
      </div>
    </div>

    <button
      [disabled]="formResponsable.invalid"
      type="submit"
      class="btn btn-primary"
    >
      Valider
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="editResponsable.close()"
    >
      Annuler
    </button>
  </form>
</ngx-smart-modal>

<!-- faire un component eleve pour le reutiliser plusieurs fois : dans popup et directement dans la page-->
<ngx-smart-modal
  #editEleve
  identifier="editEleve"
  customClass="large-modal"
  [backdrop]="false"
  [hideDelay]="0"
>
  <h1>Elève de la famille {{ famille.nom }}</h1>
  <app-eleve
    [selectedEleve]="selectedEleve"
    [isBoutonsVisibles]="true"
    (eleveSaved)="eleveSaved($event)"
    (canceled)="fermeEditEleve()"
  ></app-eleve>
</ngx-smart-modal>

<ngx-smart-modal
  #editChoixCours
  identifier="editChoixCours"
  customClass="large-modal"
  [backdrop]="false"
  [hideDelay]="0"
  *ngIf="selectedInscription"
>
  <h2>
    Pré-inscription de l'élève
    {{ selectedInscription.eleveNom ? selectedInscription.eleveNom : "" }}
    {{ selectedInscription.elevePrenom ? selectedInscription.elevePrenom : "" }}
  </h2>
  <form
    novalidate
    (ngSubmit)="saveReinscription()"
    [formGroup]="formChoixCours"
    *ngIf="formChoixCours"
  >
    <div class="form-group">
      <label for="id">Cours</label>
      <select id="id" class="form-control" formControlName="id">
        <option *ngFor="let option of listeCours" [ngValue]="option.id">
          {{ libelleCours(option) }}
        </option>
      </select>
    </div>
    <div
      *ngIf="isListeAttente(formChoixCours.value.id)"
      class="alert alert-danger"
    >
      Le cours choisi est complet, vous êtes sur liste d'attente. Nous vous
      invitons à choisir un autre cours.
    </div>
    <!--{{ selectedInscription | json }}-->
    <button
      [disabled]="editChoixCours.invalid"
      type="submit"
      class="btn btn-primary"
    >
      Valider
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="editChoixCours.close()"
    >
      Annuler
    </button>
  </form>
</ngx-smart-modal>

<ng-template
  #inscription_eleve_template
  let-row="row"
  let-rowIndex="rowIndex"
  let-value="value"
  ngx-datatable-cell-template
>
  <span class="cell-content"> {{ row.eleveNom }} {{ row.elevePrenom }} </span>
</ng-template>

<ng-template
  #inscription_professeur_template
  let-row="row"
  ngx-datatable-cell-template
>
  <span class="cell-content"
    >{{ row.professeurNom }} {{ row.professeurPrenom }}</span
  >
</ng-template>

<ngx-smart-modal #editNouveauCours identifier="editNouveauCours">
  <form
    novalidate
    (ngSubmit)="saveInscription()"
    [formGroup]="formInscription"
    *ngIf="formInscription"
  >
    <h1>Nouvelle inscription pour {{ formInscription.value.libelleEleve }}</h1>
    <div class="form-group">
      <label for="idDiscipline">Type de discipline</label>
      <div class="col-xs-12">
        <select
          id="type_discipline_id"
          class="form-control"
          formControlName="type_discipline_id"
          (ngModelChange)="typeDisciplineChanged($event)"
        >
          <option [value]="">Sélectionnez un type de discipline</option>
          <option
            *ngFor="let typeDiscipline of listeTypeDisciplines"
            [ngValue]="typeDiscipline.id"
          >
            {{ typeDiscipline.libelle }}
          </option>
        </select>
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formInscription, 'type_discipline_id')"
      >
        Type discipline obligatoire
      </div>
    </div>
    <div
      class="form-group"
      *ngIf="
        listeDisciplinesSelectionnee && listeDisciplinesSelectionnee.length > 0
      "
    >
      <label for="idDiscipline">Discipline</label>
      <div class="col-xs-12">
        <select
          id="idDiscipline"
          class="form-control"
          formControlName="idDiscipline"
          (ngModelChange)="disciplineChanged($event)"
        >
          <option [value]="">Sélectionnez une discipline</option>
          <option
            *ngFor="let discipline of listeDisciplinesSelectionnee"
            [ngValue]="discipline.id"
          >
            {{ discipline.libelle }}
          </option>
        </select>
      </div>
      <div
        class="alert alert-danger"
        *ngIf="isError(formInscription, 'idDiscipline')"
      >
        Discipline obligatoire
      </div>
    </div>

    <div class="form-group" *ngIf="listeCours && listeCours.length > 0">
      <label for="idCours">Cours</label>
      <select id="idCours" class="form-control" formControlName="idCours">
        <option *ngFor="let option of listeCours" [ngValue]="option.id">
          {{ libelleCours(option) }}
        </option>
      </select>
    </div>

    <div
      *ngIf="
        formInscription.value.messageUtilisateur &&
        formInscription.value.messageUtilisateur.length > 0
      "
    >
      {{ formInscription.value.messageUtilisateur }}
    </div>

    <button
      [disabled]="formInscription.invalid"
      type="submit"
      class="btn btn-primary"
    >
      Valider
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="editNouveauCours.close()"
    >
      Annuler
    </button>
  </form>
</ngx-smart-modal>

<ngx-smart-modal #messageInfo identifier="messageInfo">
  <h3>{{ messageInfoUtilisateur }}</h3>
  <button type="button" class="btn btn-primary" (click)="messageInfo.close()">
    Valider
  </button>
</ngx-smart-modal>
