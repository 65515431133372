<!--<div class="breadcrumb">
</div>-->
<div *ngIf="isDebug">Mode debug actif<br />{{ isCaptchaValidated || json }}</div>
<div class="row mb-lg" *ngIf="!isDataValide">
  <p *ngIf="this.params && this.params['text.before.form']" class="col-md-9 text">
    {{ this.params && this.params['text.before.form'] }}</p>
  <div class="col-md-9">
    <form novalidate (ngSubmit)="validForm()" [formGroup]="form" *ngIf="form">
      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="nom">Nom</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="nom" formControlName="nom" (input)="nom.setValue(nom.value.toUpperCase())">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'nom')">
          Nom obligatoire
        </div>
      </div>
      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="adresse1">Adresse</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="adresse1" formControlName="adresse1">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'adresse1')">
          Ligne 1 adresse obligatoire
        </div>
      </div>
      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="codePostal">Code postal</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="codePostal" formControlName="codePostal">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'codePostal')">
          Code postal mal formaté
        </div>
      </div>
      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="ville">Ville</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="ville" formControlName="ville" (input)="ville.setValue(ville.value.toUpperCase())">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'ville')">
          Ville obligatoire
        </div>
      </div>

      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="telephone">Téléphone</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="telephone" formControlName="telephone">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'telephone')">
          Téléphone mal formaté
        </div>
      </div>

      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="email">Courriel</label>
        <div class="col-xs-7">
          <input type="text" class="form-control" id="email" formControlName="email">
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'email')">
          Courriel mal formaté
        </div>
      </div>
      <p *ngIf="this.params && this.params['link']" class="text">
        {{ this.params && this.params['text.before.link'] ? this.params['text.before.link'] : '' }}
        <a *ngIf="this.params && this.params['link']" href="{{ this.params['link'] ? this.params['link'] : ''}}" target="_blank">{{this.params && this.params['link.label'] ? this.params['link.label'] : 'lien'}}</a>
      </p>
      <div class="form-group col-xs-12">
        <label class="col-xs-5" for="demande">Demande</label>
        <div class="col-xs-7">
          <textarea class="form-control" id="demande" formControlName="demande"></textarea>
        </div>
        <div class="alert alert-danger" *ngIf="isError(form, 'demande')">
          Demande doit être renseigné
        </div>
      </div>
      <div *ngIf="this.params && this.params['text.before.captcha']" class="text">
        {{ this.params && this.params['text.before.captcha'] }}</div>
      <div class="col-xs-12">
        <re-captcha (resolved)="resolved($event)" siteKey="6Le9lLEZAAAAAP_--qnSXCnzA8CHonvDX7r5QBew"></re-captcha>
      </div>

      <div class="col-xs-12" *ngIf="isCaptchaValidated">
        <button type="button" [disabled]="form.invalid" (click)="valideInscriptions()"
          class="btn btn-success">Valider</button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="this.params && this.params['text.after.form']" class="text">
  {{ this.params && this.params['text.after.form'] }}</div>
<div class="col-md-9 text-danger" *ngIf="messageUtilisateur">{{ messageUtilisateur }}</div>
<div>
  <pre-inscription-rendez-vous *ngIf="isDataValide" [listeRdvDispo]="listeRdvDispo" [idParticipant]="idCree">
  </pre-inscription-rendez-vous>
</div>
