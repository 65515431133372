
<h2 *ngIf="!rdvSelectionne">Veuillez sélectionner un rendez-vous</h2>
<div *ngIf="messageInfoUtilisateur">
  <h2>{{ messageInfoUtilisateur }}</h2>
</div>
<div class="col-xs-12" *ngIf="libelleRendezVous && !isRdvConfirme">
  <h2>Vous avez sélectionné le rendez-vous du {{ libelleRendezVous }}</h2>
  <button type="button" [disabled]="!libelleRendezVous" (click)="saveRendezVous()"
    class="btn btn-success">Valider</button>
</div>
<h2 *ngIf="isRdvConfirme">Votre rendez-vous est confirmé le {{ libelleRendezVous }}</h2>
<div *ngIf="is_courriel_envoye" class="col-md-9 text">Un courriel de confirmation vous a été envoyé à l'adresse {{ courriel ? courriel : '' }}</div>
<div *ngIf="isRdvConfirme && !is_courriel_envoye" class="col-md-9 text">Suite à unproblème technique, le courriel de confirmation n'a pas  pu être envoyé.</div>
<div class="col-xs-12" *ngIf="!isRdvConfirme">
  <full-calendar #calendar defaultView="dayGridMonth" [plugins]="calendarPlugins" themeSystem="bootstrap"
    [events]="listeRendezVousDisponibles" [locale]="options.locale" (eventClick)="eventClick($event)"
    [views]="options.views" [header]="options.header" [buttonText]="options.buttonText"
    [titleFormat]="options.titleFormat">
  </full-calendar>

</div>
