import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
/**
 * Manage the authentification of the current user
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) { }
	/**
	 * Check if the user is authenticated
	 */
	canActivate() {
		if (this.auth.authenticated()) {
			return true;
		}
		this.router.navigate(['others']);
		return false;
	}
}
