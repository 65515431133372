<div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
        <!-- <div class="navbar-header">
						<a class="minimalize-styl-2 btn btn-success " (click)="toggleNavigation()"><i class="fa fa-bars"></i> </a>
				</div> -->
				<div class="navbar-header">
					<img src="https://conservatoire-bois-colombes.fr/wp-content/uploads/2022/07/notes-2.png" class="header-image" width="100%">
					<h1>{{ libelleFamille() }}</h1>
				</div>
        <div class="nav navbar-top-links navbar-right">
					<!-- <img alt="log" class="logo"
					src="./assets/img/avatar/avatar.png" /> -->
				</div>
    </nav>
</div>
