import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { FullCalendarComponent } from '@fullcalendar/angular';
import momentPlugin, { toMoment } from '@fullcalendar/moment';
import { Calendar} from '@fullcalendar/core'
// import momentPlugin from '@fullcalendar/moment'
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const api = environment.API;

@Component({
  selector: 'pre-inscription-rendez-vous',
  templateUrl: './rendez-vous.component.html',
  styleUrls: ['./rendez-vous.component.scss']
})
export class RendezVousComponent implements OnInit, OnChanges {
  calendarPlugins = [dayGridPlugin, listPlugin, bootstrapPlugin, momentPlugin]; // important!
  public listeRendezVousDisponibles = [];
  @Input() listeRdvDispo: any[];
  @Input() idParticipant: number;
  public rdvSelectionne;
  public isRdvConfirme=false;
  public libelleRendezVous = '';
  public messageInfoUtilisateur = '';
  public is_courriel_envoye = false;
  public courriel: string;
  // public formRendezVous: FormGroup;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public options = {
    locale: 'fr',
    views: {
      listDay: { buttonText: 'jour' },
      listWeek: { buttonText: 'semaine' },
      listMonth: { buttonText: 'mois' }
    },
    header: {
      left: 'title',
      center: 'prev,next today',
      right: '  listDay,listWeek,listMonth dayGridDay,dayGridWeek,dayGridMonth' // listDay,listWeek,listMonth
    },
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'mois',
      week: 'semaine',
      day: 'jour'
    },
    titleFormat: 'D MMMM YYYY'
  }

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log({ changements: changes });
    if (changes.listeRdvDispo) {
      this.initListeRdv();
    }
  }
  initListeRdv() {
    this.listeRendezVousDisponibles = [];
    if (this.listeRdvDispo) {
      this.listeRendezVousDisponibles = [];
      this.listeRdvDispo.forEach((rdv => {
        // console.log(rdv);
        rdv.extendedProps = {
          start: rdv.start,
          end: rdv.end,
          idTypeRDV: rdv.idTypeRDV, //this.formRendezVous.value.typeRendezVous.id,
        };
        rdv.title = 'Sélectionner'
        this.listeRendezVousDisponibles.push(rdv);
        //{ id: 1, title: 'Sélectionner', start: '2019-05-10 10:00', end: '2019-05-10 10:15' },
      }));
      if (this.listeRendezVousDisponibles && this.listeRendezVousDisponibles.length>0) {
        //this.options.;
        let calendarApi = this.calendarComponent.getApi();
        // calendarApi.gotoDate('2020-09-01');
        calendarApi.gotoDate(this.listeRendezVousDisponibles[0].start);
        // console.log({ firstRdv: this.listeRendezVousDisponibles[0].start, calendar: this.calendarComponent });
      }
      // console.log(this.listeRendezVousDisponibles);
    }
  }

  eventClick(event) {
    // let calendarApi = this.calendarComponent.getApi();
    this.rdvSelectionne = event.event;
    // console.log({ event: event.event, title: event.event.title, start: event.event.start, rdv: event.extendedProps, id: event.id });
    let m = toMoment(event.event.start, this.calendarComponent.getApi());
    let fin = toMoment(event.event.end, this.calendarComponent.getApi()) ;
    this.libelleRendezVous = m.format("DD/MM/YYYY") + " de " + m.format("HH:mm") + " à " + fin.format("HH:mm");
    this.messageInfoUtilisateur = '';
    // alert('Vous avez sélectionné le rendez-vous du ' + m.format("DD/MM/YYYY HH:mm") + ". Le rendez-vous se terminera à " + fin.format("HH:mm"));
    // Test la disponibilité du rendez-vous
    /*const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'estRDVDispo')
        .set('params', JSON.stringify([this.rdvSelectionne.extendedProps, false, this.famille['id'], localStorage.getItem('anneeScolaire')]))
    };
    this.http.get(api, options).subscribe(data => {
      console.log(data);
      if (data['isDispo']) {
        console.log('Le rdv est toujours disponible');
      } else {
        console.log('Le rdv n est plus disponible => recupere la liste des rdv dispos dans rdv_dispo');
        this.messageInfoUtilisateur = "Le rendez-vous sélectionné a été réservé par un autre utilisateur. Veuillez en choisir un autre.";
        this.messageInfo.open();
        const listeRdvDispo = <any[]>data['rdv_dispo'];
        this.initListeRdv(listeRdvDispo);
      }
    },
      err => console.log(err));
      */
  }

  saveRendezVous() {
    console.log({ ou: 'saveRendezVous', rdvSelectionne: this.rdvSelectionne, idParticipant: this.idParticipant, rdvToSend: this.rdvSelectionne.extendedProps });
    if (this.rdvSelectionne) {
      console.log(this.rdvSelectionne.extendedProps);
      const options = {
        params: new HttpParams().set('service', 'ServicePortailPreInscription')
          .set('method', 'estRDVDispo')
          .set('params', JSON.stringify([this.rdvSelectionne.extendedProps, true, this.idParticipant]))
      };
      this.http.get(api, options).subscribe(data => {
        console.log(data);
        if (data['isValide']) {
          console.log('Le rdv est valide');
          // TODO passer à la dernière étape qui affiche message de confirmation et clos la session 5 minutes plus tard
          this.isRdvConfirme = true;
          this.is_courriel_envoye = data['courriel_envoye'];
          this.courriel = data['courriel'];
        } else if (!data['isDispo']) {
          console.log('Le rdv n est plus disponible => recupere la liste des rdv dispos dans rdv_dispo');
          this.messageInfoUtilisateur = "Le rendez-vous sélectionné n'est plus disponible. Veuillez en choisir un autre.";
          // this.messageInfo.open();
          const listeRdvDispo = <any[]>data['rdv_dispo'];
          this.listeRdvDispo = listeRdvDispo;
          this.initListeRdv();
        }
      },
        err => console.log(err));
    }
  }
}
