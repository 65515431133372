import {OnInit, Component} from "@angular/core";
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@shared/services/auth/auth.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
		styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    public session_expire = false;
    public data;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private http: HttpClient,
    ) { }

    ngOnInit() {
        // this.route.paramMap;
        console.log('HomeComponent');
        // console.log(this.route.snapshot.paramMap);
        if (this.route.snapshot.paramMap.has('token')) {
            const token = this.route.snapshot.paramMap.get('token');
            console.log('token:', token);
            const res = this.authService.loginAuto(this.route).subscribe(
                data=>{
                    console.log(data);
                    // Rechercher les données de la famille
                    this.searchDonneesFamille(localStorage.getItem('id_famille'), localStorage.getItem('anneeScolaire'));
                    
                },
                (error)=>{console.log(error)}
                );
            console.log(res);
        } else if (localStorage.getItem('id_famille') && localStorage.getItem('anneeScolaire')) {
            console.log('Données familles déjà chargées');
        } else {
            console.log('pas de token');
            this.router.navigate(['/']);
        }
    }

    dataFamilleSaved(avecValidation) {
        // Recharger les données
        console.log('dataFamilleSaved : rechargement données', avecValidation);
        if (avecValidation) {
            // éventuellement voir pour charger les données factures et les afficher
            this.session_expire=true;
            this.data=null;
            // TODO aller sur une page avec messages personnalisés et stocker le message dans le local storage ?
            this.router.navigate(['/fin']);
        } else {
            // Inutile de recharger car on perd des infos non sauvegardées (eleve validé)
            // this.searchDonneesFamille(localStorage.getItem('id_famille'), localStorage.getItem('anneeScolaire'));
        }
    }
    searchDonneesFamille(id_famille, anneeScolaire) {
        this.session_expire=false;
        console.log('searchDonneesFamille', id_famille);
        const api = environment.API;
        const options = {
            params: new HttpParams().set('service', 'ServicePortailFamille')
              .set('method', 'selectInfosFamille')
              .set('params', JSON.stringify([id_famille, anneeScolaire, true])) // true pour prende en compte les modifications déjà effectuées par la famille et ni validées ni rejetées
          };
        this.http.get(api, options).subscribe(data =>
            {
                console.log(data);
                if (data['success']==false && data['error_code']==99) {
                    // Token expiré
                    this.session_expire=true;
                }
                this.data = data;
            })
    }
}