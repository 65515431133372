import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@shared/services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthService,
        private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //let token = localStorage.getItem("id_token");
        // console.log('intercept', request, next);
        /*const authReq = request.clone({
            headers: new HttpHeaders({
                'token': token ? token : '',
            })
        });*/

        return next.handle(request).pipe(catchError(err => {
            console.log('error request',err);
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            } else if (err.status === 0 || err.status === 504) {
                // il retourne un status à 0 quand l'API ne répond pas (pourquoi ?), par exemple si le container server n'est pas démarré
                //this.authenticationService.logout();
                //location.href='error';
                console.log('serveur KO');
                this.router.navigate(['error']); // Voir pour créer une page indiquant que le serveur ne répond pas réessayer plus tard dans ce cas.
            }


            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}