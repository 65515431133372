import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { JwtModule } from "@auth0/angular-jwt";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import localeFR from "@angular/common/locales/fr";

import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopnavbarComponent } from "./components/topnavbar/topnavbar.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { ErrorInterceptor } from "@shared/helpers/error.interceptor";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { environment } from "src/environments/environment";
import { Error404Component } from "./pages/error404/error404.component";
import { FamilleComponent } from './components/famille/famille.component';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSmartModalModule } from 'ngx-smart-modal';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FinComponent } from './pages/fin/fin.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EleveComponent } from './components/eleve/eleve.component';
import { InscriptionsComponent } from './components/inscriptions/inscriptions.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RendezVousComponent } from './components/rendez-vous/rendez-vous.component';
import {
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

registerLocaleData(localeFR, 'fr');

export function tokenGetter() {
  // return localStorage.getItem("id_token");
  var token = localStorage.getItem("id_token");
  return token;
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TopnavbarComponent,
    HomeComponent,
    Error404Component,
    FamilleComponent,
    FinComponent,
    EleveComponent,
    InscriptionsComponent,
    RendezVousComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
        tokenGetter: tokenGetter,
        authScheme: "",
        whitelistedDomains: [environment.server] //[environment.API],'test.eveasoft.com'
      }
    }),
    FullCalendarModule,
    RecaptchaModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "fr" },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
