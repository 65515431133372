import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Login} from '../../shared/models/login';
import { smoothlyMenu } from '../../shared/helpers/app.helpers';

declare var jQuery: any;

@Component({
    selector: 'app-navigation',
		templateUrl: './navigation.component.html',
		styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    @Input() loginInfo: Login;
    constructor( private router: Router) { }

    ngOnInit() { }
    activeRoute(routename: string): boolean {
        return this.router.url.indexOf(routename) > -1;
		}
		toggleNavigation(): void {

			jQuery('body').toggleClass('mini-navbar');
			smoothlyMenu();
		}
}
