<nav class="navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav " id="side-menu">
				<li>
					<a (click)="toggleNavigation()"><i class="fa fa-bars"></i></a>
				</li>
    </ul>
    <ul class="nav metismenu middle" id="side-menu">
      <div>

      </div>
      <!--<li class="middle" [ngClass]="{active: activeRoute('home')}">
        <a [routerLink]="['./home']"><i class="fa fa-signal"></i> <span class="nav-label">Dashboards</span></a>
      </li>-->
      <!--<li class="middle" [ngClass]="{active: activeRoute('others')}">
        <a [routerLink]="['./others']"><i class="fa fa-signal"></i> <span class="nav-label">Others</span></a>
      </li>-->
    </ul>

  </div>
</nav>
