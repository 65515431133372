import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AuthService } from "@shared/services/auth/auth.service";

const api = environment.API;
/* Utilisation de l'API :
const options = {
      params: new HttpParams().set('service', 'ServicePortailPreInscription')
        .set('method', 'choixReinscription')
        .set('params', JSON.stringify([null, idDiscipline, localStorage.getItem('anneeScolaire')]))
    };
    this.http.get(api, options).subscribe()
*/
const telRegExp = '^0[0-9]{9}$';
const courrielRegex = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$)';
const codePostalRegExp = '^(([0-8][0-9])|(9[0-5]))[0-9]{3}$';
const messageSiEmailDejaUtilise = "Cette adresse mail a déjà été utilisée pour réserver un rendez-vous, merci de joindre le conservatoire par mail ou par téléphone, si vous souhaitez modifier le rendez-vous ou pour tout autre motif relatif à votre demande d'inscription au conservatoire";
@Component({
  selector: 'app-inscriptions',
  templateUrl: './inscriptions.component.html',
  styleUrls: ['./inscriptions.component.scss']
})
export class InscriptionsComponent implements OnInit, OnChanges {

  //https://www.npmjs.com/package/ng-recaptcha
  //côté API : https://developers.google.com/recaptcha/docs/verify
  public isDebug: boolean = true;
  public isCaptchaValidated: boolean = false;
  public captchaResponse: string = '';
  public isDataValide: boolean = false;
  public idCree;
  public form: FormGroup;
  public messageUtilisateur: string ="";
  public params: any; // paramètrage des textes
  //Rendez-vous
  public listeRdvDispo;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log({ changements: changes });
  }
  ngOnInit() {
    localStorage.clear();
    const options = {
      params: new HttpParams().set('service', 'ServicePortailPreInscription')
        .set('method', 'getParametrage')
    };
    this.http.get(api, options).subscribe(data => {
      console.log({ data, ou: 'getParametrage', params: data['params']});
      this.params = JSON.parse(data['params']);
      console.log({
        params: this.params,
        emailUsed: this.params && this.params['email.used'] ? this.params['email.used'] : 'pas de paramétrage',
      });
    })
    console.log('ngOnInitla suite');
    this.isDebug = this.route.snapshot.queryParamMap.get('debug') == 'true';
    this.initForm();
  }
  initForm() {
    this.form = this.formBuilder.group({
      nom: [
        '',
        [Validators.required, Validators.maxLength(30)],
      ],
      adresse1: [
        '',
        [Validators.required, Validators.maxLength(200)],
      ],
      //adresse2: [
      //  '',
      //],
      codePostal: [
        '',
        [Validators.required, Validators.maxLength(5), Validators.pattern(codePostalRegExp)],
      ],
      ville: [
        '',
        [Validators.required, Validators.maxLength(100)],
      ],
      telephone: [
        '',
        [Validators.required, Validators.maxLength(14), Validators.pattern(telRegExp)],
      ],
      email: [
        '', [Validators.required, Validators.maxLength(50), Validators.pattern(courrielRegex)],
      ],
      demande: [
        '', Validators.required,
      ],
    });
  }
  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse) {
      const options = {
        params: new HttpParams().set('service', 'ServicePortailPreInscription')
          .set('method', 'verifyCaptcha')
          .set('params', JSON.stringify([captchaResponse]))
      };
      console.log({ ou: 'resolved', captchaResponse });
      this.http.get(api, options).subscribe(data => {
        this.controleRetourCaptcha(data);
      })
    } else {
      const data = {
        isOk: false
      };
      this.controleRetourCaptcha(data);
    }

  }
  private controleRetourCaptcha(data) {
    // console.log(data);
    this.isCaptchaValidated = data && data['isOk'];
    if (this.isCaptchaValidated) {
      // console.log("captcha validé");
      localStorage.setItem('id_token', data['token']);
    } else {
      console.log("captcha KO");
      localStorage.removeItem('id_token');
    }
  }
  public isError(form: FormGroup, chp: string): boolean {
    return form.get(chp) && form.get(chp).invalid && form.get(chp).touched;
  }
  public valideInscriptions() {
    console.log({ ou: 'valideInscriptions', form: this.form, isInvalid: this.form.invalid, token: localStorage.getItem('id_token') });
    if (this.form.invalid) {
      // Des données ne sont pas bien saisies
      return;
    }
    let obj: Object = this.form.value;
    const options = {
      params: new HttpParams().set('service', 'ServicePortailPreInscription')
        .set('method', 'saveDataFamille')
        .set('params', JSON.stringify([obj, this.captchaResponse]))
    };
    this.messageUtilisateur = ""; // Réinitialisation du message affiché à l'utilisateur
    // console.log({ ou: 'envoi donnees serveur', data: obj });
    this.http.get(api, options).subscribe(data => {
      // console.log(data);
      if (!data) {
        // Pas de données =>  comment est-ce possible ?
        this.router.navigate(['error']);
        return;
      }
      if (data['isOk']) {
        this.idCree = data['id'];
        this.isDataValide=true;
        this.rechercheListeRDV();
      } else {
        if (data['emailUtilise']) {
          this.messageUtilisateur = this.params && this.params['email.used'] ? this.params['email.used'] : messageSiEmailDejaUtilise;
        } else {
          this.router.navigate(['error']);
        }
      }
    })
  }
  public rechercheListeRDV() {
    //selectRDVDisponibles
    const options = {
      params: new HttpParams().set('service', 'ServicePortailPreInscription')
        .set('method', 'selectRDVDisponibles')
        //.set('params', JSON.stringify([obj, this.captchaResponse]))
    };
    this.http.get(api, options).subscribe(data => {
      console.log({ ou: 'rechercheListeRDV', data});
      this.listeRdvDispo = data;
    })
  }
}
