import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

const api = environment.API;
const TelPortableRegExp = '^0[6-7][0-9]{8}$';
const courrielRegex = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)';

@Component({
  selector: 'app-eleve',
  templateUrl: './eleve.component.html',
  styleUrls: ['./eleve.component.scss']
})
export class EleveComponent implements OnInit, OnChanges {
  @Input() selectedEleve: Object;
  //@Input() famille_nom: string;
  @Input() isBoutonsVisibles; boolean = true;

  @Output() eleveSaved: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() canceled = new EventEmitter();

  public formEleve: FormGroup;


  // Listes déroulantes
  public listeNiveauScolaire = [];
  public listeStatutEleve = [];
  public listeCivilite = [];
  public listeSexe = [
    {
      id: 'M',
      libelle: 'Masculin'
    },
    {
      id: 'F',
      libelle: 'Féminin'
    }
  ];
  private subListes: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.loadDataListes();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log({ changements: changes, eleve: this.selectedEleve});
    if (changes.selectedEleve) {
      if (this.selectedEleve) {
        this.createFormEleve(this.selectedEleve);
      }
    }
  }
  private createFormEleve(row: Object) {
    this.formEleve = this.formBuilder.group({
      id: [
        row['id'],
      ],
      idCreation: [
        row['idCreation'],
      ],
      isCreation: [
        row['isCreation'],
      ],
      isCreationFamille: [
        row['isCreationFamille'],
      ],
      nom: [
        row['nom'],
        Validators.required,
      ],
      prenom: [
        row['prenom'],
        Validators.required,
      ],
      sexe: [
        row['sexe'],
      ],
      dateNaissStr: [
        row['dateNaissStr'],
        Validators.required,
      ],
      email: [
        row['email'], Validators.pattern(courrielRegex),
      ],
      telephonePortable: [
        row['telephonePortable'], Validators.pattern(TelPortableRegExp),
      ],
      civilite: [
        row['civilite'],
      ],
      civilite_libelle: [
        row['civilite_libelle'],
      ],
      statut: [
        row['statut'],
        Validators.required,
      ],
      niveauScolaire: [
        row['niveauScolaire'],
      ],
      accordMedia: [
        row['accordMedia'] == 1 ? true : false,
      ],
      bool_01: [
        row['bool_01'] == 1 ? true : false,
      ],
    });
  };


  saveEleve() {
    console.log('saveEleve', { eleve: this.selectedEleve, form: this.formEleve });
    const isCreation = this.selectedEleve['isCreation'] === true;
    this.selectedEleve['isValide'] = true;
    // Majuscules
    this.formEleve.patchValue({nom: this.formEleve.value.nom.toUpperCase(), prenom: this.formEleve.value.prenom?(this.formEleve.value.prenom.charAt(0).toUpperCase() + this.formEleve.value.prenom.substring(1)):''});
    if (isCreation
      || this.selectedEleve['email'] != this.formEleve.value.email
      || this.selectedEleve['telephonePortable'] != this.formEleve.value.telephonePortable
      || this.selectedEleve['statut'] != this.formEleve.value.statut
      || this.selectedEleve['niveauScolaire'] != this.formEleve.value.niveauScolaire
      || this.selectedEleve['accordMedia'] != this.formEleve.value.accordMedia
      || this.selectedEleve['bool_01'] != this.formEleve.value.bool_01
      || this.selectedEleve['civilite'] != this.formEleve.value.civilite
    ) {
      if (this.formEleve.value['dateNaissStr']) {
        // Conversion date en string yyyy-mm-dd (format envoyé par le serveur)
        const dateValue = this.formEleve.value['dateNaissStr'];
        this.formEleve.patchValue({
          dateNaissStr: new DatePipe('fr-FR').transform(dateValue, 'yyyy-MM-dd'),
        })
        console.log('date convertie pour enregistrement', this.formEleve.value['dateNaissStr']);
      }
      if (this.selectedEleve['civilite'] != this.formEleve.value.civilite) {
        // civilite modifie
        const civilite = this.listeCivilite.find(elt=>elt.id == this.formEleve.value.civilite);
        if (civilite) {
          this.formEleve.patchValue({civilite_libelle: civilite.libelle});
        }
      }
      this.formEleve.patchValue({isValide: true});
      this.eleveSaved.emit(this.formEleve.value);
    }
    else {
      console.log('pas de modification sur l eleve');
      this.eleveSaved.emit(null); // Si pas de modification
    }
  }


  public isError(form: FormGroup, chp: string): boolean {
    return form.get(chp) && form.get(chp).invalid && form.get(chp).touched;
  }
  loadDataListes() {
    if (this.subListes) {
      this.subListes.unsubscribe();
    }
    const optionsCivilite = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_civilite']))
    };
    const optionsNiveauScolaire = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_niveau_scolaire']))
    };
    const optionsStatutEleve = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_statut_eleve']))
    };
    this.subListes=forkJoin([
      this.http.get(api, optionsNiveauScolaire),
      this.http.get(api, optionsStatutEleve),
      this.http.get(api, optionsCivilite)
      ]
    )
      .subscribe(data => {
        const [dataNiveauScolaire, dataStatutEleve, dataCivilite] = data;
        // console.log(data, this.listeNiveauScolaire);
        const dataVide = { id: null, libelle: '' };

        if (!Array.isArray(dataNiveauScolaire)) {
          let dataNiveauSColaire=[];
        }
        this.listeNiveauScolaire = <Object[]>dataNiveauScolaire;
        if (!Array.isArray(this.listeNiveauScolaire)) {
          this.listeNiveauScolaire = [];
        }
        this.listeNiveauScolaire = [... this.listeNiveauScolaire]
        this.listeNiveauScolaire.unshift(dataVide);

        if (!Array.isArray(dataCivilite)) {
          let dataCivilite=[];
        }
        this.listeCivilite = <Object[]>dataCivilite;
        if (!Array.isArray(this.listeCivilite)) {
          this.listeCivilite = [];
        }
        this.listeCivilite = [... this.listeCivilite]
        // this.listeCivilite.unshift(dataVide);

        if (!Array.isArray(dataStatutEleve)) {
          let dataStatutEleve=[];
        }
        this.listeStatutEleve = <Object[]>dataStatutEleve;
        if (!Array.isArray(this.listeStatutEleve)) {
          this.listeStatutEleve = [];
        }
        this.listeStatutEleve = [... this.listeStatutEleve]
        // this.listeStatutEleve.unshift(dataVide);
      },
        err => console.log(err));
  }
}
