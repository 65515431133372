import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {Observable, Observer, Subscriber} from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
/**
 * Authentification services
 */
@Directive()
@Injectable()
export class AuthService {
	/**
	 * Output isAuthenticated using EventEmitter
	 */
	@Output() isAuthenticated: EventEmitter<any> = new EventEmitter();
	/**
	 * Application api url
	 */
	private baseUrl: string;
	/**
	 * Current user
	 */
	private currentUser: any;
	/**
	 * Promise
	 */
	private currentUserPromise: Promise<any>;

	constructor(
		private http: HttpClient,
		private router: Router,
		private route: ActivatedRoute,
		private helper: JwtHelperService
	) {
	}
	loginAuto(activatedRoute: ActivatedRoute):Observable<any> {
		const token = activatedRoute.snapshot.params.token;
		console.log('loginAuto token=', token, activatedRoute);
		// Vérifier si le token correspond à un token émis par LEA
		/*const options = {
			service: 'token',
			method: 'token',
			params: JSON.stringify([token]),
			//params: new HttpParams().set('service', 'token')
			  //.set('method', 'token')
			  //.set('params', token)
			  //.set('params', JSON.stringify([token]))
		  };*/
		  // Test avec appel service eleve qui retourne bien un resultat
		  /*const options = {
			params: new HttpParams().set('service', 'ServiceEleve')
			  .set('method', 'findById')
			  .set('params', JSON.stringify([103, 2013])) // JSON.stringify({ token: token })
		  };*/
		  // ok en passant les paramètres depuis le front
		  const options = {
			params: new HttpParams().set('service', 'ServicePortailFamille')
			  .set('method', 'isLienFamilleValide')
			  .set('params', JSON.stringify([token])) // JSON.stringify({ token: token })
		  };
		const api = environment.API; // 'https://test.eveasoft.com/php/api/api.php'; // environment.API
		/*this.http.get(api, { params: options})
				.pipe(map(response => console.log(response)))
				.subscribe(data => {
					console.log(data);
				})*/

		console.log('Appel API ', api, );
		return new Observable((subscriber: Subscriber<any> )=> {
			// this.http.post(api, { params: options})
			this.http.get(api, options)
				/*.pipe(
					map(
						(res:Response) =>
							res.json() )) // response.json(); // console.log(response)*/
				.subscribe(data => {
					console.log(data);
					if (data['isLienFamilleValide']) {
						console.log('Lien famille valide');
						localStorage.setItem('id_token', data['token']);
						localStorage.setItem('id_famille', data['idFamille']);
						localStorage.setItem('anneeScolaire',data['anneeScolaire']);
						subscriber.next(data['idFamille']);
						subscriber.complete();
					} else {
						console.log('lien famille erroné => aller à la page d erreur ou de connexion');
						localStorage.removeItem('id_token');
						subscriber.error();
						subscriber.complete();
						this.router.navigate(['login']);
					}
				}, error=>{console.log(error)})
		});
	}
	/**
	 * Manage the login
	 * @param email email from the login form
	 * @param password password from the login form
	 */
	login(email, password) {
		console.log('AuthService');
		console.log('AuthService', this.route, this.route.snapshot, this.route.params, this.route.queryParamMap);
		/*return Observable.create(subscriber => {

            this.http
                .get(this.baseUrl + '/login', { email, password })
                .map(response => response.json())
                .subscribe(
                    data => {
                        localStorage.setItem('id_token', data.access_token);

                        this.getCurrentUser().then(
                            user => {
                                subscriber.next(user);
                                subscriber.complete();
                            },
                            error => {
                                subscriber.error(error);
                                subscriber.complete();
                            }
                        );
                    },
                    error => {
                        localStorage.removeItem('id_token');
                        console.error(
                            'Service authentification',
                            'Authentication failed ',
                            error
                        );
                        subscriber.error(error);
                    },
                    () => {
                        console.info(
                            'Service authentification',
                            'Authentication request completed'
                        );
                    }
                );
		});*/



		/*if (token) {
			console.log(token);
			// Vérifier si le token correspond à un token émis par LEA
			const options = {
			  service: 'token',
			  method: 'token',
			  params: JSON.stringify([token]),
			  //params: new HttpParams().set('service', 'token')
				//.set('method', 'token')
				//.set('params', token)
				//.set('params', JSON.stringify([token]))
			};
			const api = environment.API; // 'https://test.eveasoft.com/php/api/api.php'; // environment.API
			console.log('appel de l API', api, options);
			if (this.sub) {
			  this.sub.unsubscribe();
			}
			this.sub = this.http
			  .post(api, { params: options} )
			  .subscribe(data=>{
				console.log(data);
			}, error => {
			  console.log(error);
			})
		  } else {
			console.log('pas de token');
		  }*/
	}
	/**
	 * Boolean, Return if the user is authenticated and if his token is not expired
	 */
	authenticated() {
		console.log('authenticated');
		const token = localStorage.getItem('id_token');
		// console.log('token', token)
		if (!this.helper.isTokenExpired(token)) {
			console.log('token valide');
			this.isAuthenticated.emit(true);
			return true;
		} else {
			console.log('token expiré');
			this.isAuthenticated.emit(false);
			return false;
		}
	}

	/**
	 * Manage the logout
	 */
	logout() {
		console.log('logout');
		localStorage.removeItem('id_token');
		this.isAuthenticated.emit(false);
		this.currentUser = null;
		this.router.navigate(['login']);
	}
	/**
	 * Get the id_token from localStorage
	 */
	getToken() {
		console.log('getToken');
		return localStorage.getItem('id_token');
	}

}
