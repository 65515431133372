<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
               
                <div class="ibox-content">
                    <div *ngIf="session_expire">Votre session a expiré.</div>
                    <app-famille *ngIf="!session_expire && data && data.success" [famille]="data" (saved)="dataFamilleSaved($event)"></app-famille>
                    <div *ngIf="!session_expire && data && data.success==false">Erreur de récuparation des données. <br>
                        {{ data | json }}
                    </div>

                </div>
            </div>
        </div>


    </div>
</div>