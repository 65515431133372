import { Component, OnInit } from '@angular/core';
import { smoothlyMenu } from '../../shared/helpers/app.helpers';

declare var jQuery: any;
@Component({
	selector: 'app-topnavbar',
	templateUrl: 'topnavbar.component.html',
	styleUrls: ['./topnavbar.component.scss']
})
export class TopnavbarComponent implements OnInit {
	ngOnInit() {
		localStorage.getItem('user')
	}
	toggleNavigation(): void {

		jQuery('body').toggleClass('mini-navbar');
		smoothlyMenu();
	}
	logout() {
		localStorage.clear();
		// location.href='http://to_login_page';
	}
	libelleFamille() {
		const libelle = localStorage.getItem('title');
		return libelle ? 'Famille ' + libelle : 'Portail famille';
	}
}